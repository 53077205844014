<template>
  <div>
    <TTableAdvance
      :items="cards"
      :fields="fields"
      store="accounting.cards"
      @click-clear-filter="clearFilter"
      resource="/accounting/account/bank-card"
      enterable
      creatable
      @click-create="showModalCreate = true"
    >
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <template #bank="{ item }">
        <td>
          <TMessage v-if="item.bank" :content="item.bank.name" noTranslate />
          <TMessage v-else :content="item.bank_id" noTranslate />
        </td>
      </template>
      <template #currency="{ item }">
        <td>
          <SMessageCurrency :id="item.currency_id" />
        </td>
      </template>
      <template #account_id="{ item }">
        <td>
          <SMessageAccount :value="item.account_id" />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
        </td>
      </template>
      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>
      <template #name-filter>
        <TInputText :value.sync="filter.name" @update:value="filterChange" />
      </template>
      <template #currency-filter>
        <SSelectCurrency
          :value.sync="filter.currency_id"
          @change="filterChange"
        />
      </template>
      <template #account_id-filter>
        <SSelectAccount
          :value.sync="filter.account_id"
          @change="filterChange"
          :prepend="[{ id: '', name: $t('All') }]"
        />
      </template>
    </TTableAdvance>
    <CardModal :show.sync="showModalCreate" />
  </div>
</template>

<script>
import CardModal from "./CardModal.vue";
import { mapGetters } from "vuex";
export default {
  components: { CardModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Card Id", _style: "min-width: 200px; width: 200px" },
        {
          key: "name",
          label: "Card name",
          _style: "min-width: 200px; width: 200px",
        },
        {
          key: "bank",
          label: "Bank",
          _style: "min-width: 200px; width: 300px",
        },
        {
          key: "currency",
          label: "Currency",
          _style: "min-width: 150px; width: 150px",
          _classes: "text-wrap",
        },
        {
          key: "account_id",
          label: "Accounting account",
          _style: "min-width: 200px; width: 200px",
          _classes: "",
        },
        { key: "balance", label: "Balance", sorter: true },
      ],
      showModalCreate: false,
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    this.$store.dispatch("accounting.cards.apply-query", {});
  },
  computed: {
    ...mapGetters({
      cards: "accounting.cards.list",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch("accounting.cards.apply-query", filterFields);
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter = {};
      this.filter_between = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
